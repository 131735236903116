<template>
	<div class="modifypwd">

		<el-container>
			<el-main>
				<div class="modifypwdcontent">
					<h1 >通过手机重置密码</h1>
					<el-row style="width: 100%;">
						<el-col :span="5" :offset="1"><strong>重置密码</strong></el-col>
						<el-col :span="5" :offset="13"><a href="#" @click="goLogin" style="padding-right:20px;">返回登录</a></el-col>
					</el-row>
					<!-- 进度条 -->
					<el-row style="margin-top: 1.875rem;width: 100%;">
						<el-col :span="24" style="width: 100%;">
							<el-steps :active="active" align-center style="width:100%;">
								<el-step  title="验证手机号码"></el-step>
								<el-step title="设置新密码"></el-step>
								<el-step title="密码重置成功"></el-step>
							</el-steps>
						</el-col>
					</el-row>

					<el-row style=" width: 85%;margin: 0px auto;margin-top: 10%;">
						<el-col :span="22" :offset="1">
							<!-- 验证手机号码 -->
							<div class="checkPhone" :hidden="checkPhoneHide">
								<el-form :model="modifyPwdForm" :rules="rules" ref="modifyPwdForm">
									<!-- 手机号 -->
									<el-form-item prop="phone">
										<el-input placeholder="请输入手机号" v-model="modifyPwdForm.phone">
											<template slot="prefix"><img src="../assets/register/phone.png" width="20"
													height="20" style="margin-top: 0.3125rem;" /></template>
										</el-input>
									</el-form-item>
									<!-- 验证码 -->
									<el-form-item prop="userCode" style="width: 100%;">
										<el-input placeholder="请输入短信验证码" v-model="modifyPwdForm.userCode">
											<template slot="prefix"><img src="../assets/register/code.png" width="20"
													height="20" style="margin-top: 0.3125rem;" /></template>
											<template slot="suffix"><button style="width: 150px;border:2px solid #0D8EA4;color: white;font-size: 14px;" :disabled="disabled" type="button" id="c"
													@click="getCode">{{phoneCode}}</button></template>
										</el-input>
									</el-form-item>
									<!--下一步按钮 -->
									<el-form-item>
										<el-button style="width: 100%;" type="primary"
											@click="submitForm1('modifyPwdForm')">下一步</el-button>
									</el-form-item>
								</el-form>
							</div>
							<!-- 输入新密码 -->
							<div class="inputNewPwd" :hidden="inputNewPwdHide">
								<el-form :model="modifyPwdForm1" :rules="rules1" ref="modifyPwdForm1">
									<!-- 密码 -->
									<el-form-item prop="userPwd">
										<el-input type="password" placeholder="请设置6~20位登录密码"
											v-model="modifyPwdForm1.userPwd">
											<template slot="prefix"><img src="../assets/register/pwd.png" width="20"
													height="20" style="margin-top: 0.3125rem;" /></template>
										</el-input>
									</el-form-item>
									<!-- 再次输入新密码 -->
									<el-form-item prop="reUserPwd">
										<el-input type="password" placeholder="请再次输入6~20位新登录密码"
											v-model="modifyPwdForm1.reUserPwd" class="reUserPwd">
											<template slot="prefix"><img src="../assets/register/pwd.png" width="20"
													height="20" style="margin-top: 0.3125rem;" /></template>
										</el-input>
									</el-form-item>
									<!--确定按钮 -->
									<el-form-item>
										<el-button style="width: 100%;" type="primary"
											@click="submitForm('modifyPwdForm1')">确定</el-button>
									</el-form-item>
								</el-form>
							</div>
							<!-- 修改成功 -->
							<div class="modifySuccess" :hidden="modifySuccessHide">
								<el-row style="margin-top: -2.5rem;">
									<el-col :xl="24">
										<el-result icon="success" title="密码重置成功" :subTitle="subTitle">
											<template slot="extra">
												<el-button type="primary" size="medium">立即前往</el-button>
											</template>
										</el-result>
									</el-col>
								</el-row>
							</div>
						</el-col>
					</el-row>
				</div>
			</el-main>
			<el-footer class="f">
				Copyright © www.techlion,.com.cn All Rights Reserved.<br />
				山东泰克莱恩 8008888888
			</el-footer>

		</el-container>

	</div>
</template>

<script>
	export default {

		data: function() {
			//判断两次输入密码是否一致
			var validatePass2 = async (rule, value, callback) => {
				if (value === '') {
					return callback(new Error('请再次输入密码'));
				} else if (this.modifyPwdForm1.reUserPwd !== this.modifyPwdForm1.userPwd) {
					return callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			//判断手机号是否存在
			var phoneIdExists = (rule, value, callback) => {
				this.$axios.get("/checkPhone", {
					params: {
						phone: value
					}
				}).then(res => {
					console.log(res.data.data.exist == "no");
					if (res.data.data.exist == "no") {
						this.phoneisExists=false;
						console.log(1);
						callback(new Error('手机号不存在'));
					} else {
						callback();
					}
				}).catch(err => {
					this.$message.err("请联系管理员");
				});

			};
			//验证验证码
			var checkValidateCode = (rule, value, callback) => {
				console.log(value);
				console.log(this.yzCode);
				if (value != this.yzCode) {
					callback(new Error('验证码输入有误'));
				} else {
					callback();
				}
			}
			return {
				disabled: false, //控制按钮的是否禁用
				phoneisExists:true,
				phoneCode: "获取验证码",
				yzCode: 0, //接收后台返回的验证码
				active: 0,
				checkPhoneHide: false, //显示验证手机号的div
				inputNewPwdHide: true, //隐藏设置新密码的div
				modifySuccessHide: true, //隐藏了成功的div
				subTitle: '3秒后自动跳转至登录页',
				modifyPwdForm: {
					phone: '',
					userCode: '',
					userPwd: ''
				},
				modifyPwdForm1: {
					phone: '',
					userCode: '',
					userPwd: '',
					reUserPwd: ''
				},
				rules1:{
					userPwd: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						{
							min: 6,
							max: 18,
							message: '长度为6到18个字符',
							trigger: 'trigger'
						},
					],
					reUserPwd: [{
							required: true,
							message: '请输入密码',
							trigger: 'blur'
						},
						{
							min: 6,
							max: 18,
							message: '长度为6到18个字符',
							trigger: 'trigger'
						},
						{
							validator: validatePass2,
							trigger: 'blur'
						}
					],
				},
				rules: {
					phone: [{
							required: true,
							message: '请输入手机号',
							trigger: 'blur'
						},
						{
							validator: phoneIdExists,
							trigger: 'blur'
						},
						{
							min: 11,
							max: 11,
							message: '长度为11个字符',
							trigger: 'blur'
						}
						/* {
							pattern: /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/,
							message: '手机号格式有误'
						}, */

					],
					
					userCode: [{
							required: true,
							message: '请输入短信验证码',
							trigger: 'blur'
						},
						{
							min: 6,
							max: 6,
							message: "验证码为六位数字"
						}, {
							validator: checkValidateCode,
							trigger: 'blur'
						}
					]
				},

			}
		},
		methods: {
			//返回登录
			goLogin() {
				this.$router.push("/")
			},
			//获取验证码
			/* getCode() {
				console.log(this.phoneisExists);
				if(this.phoneisExists==false){
					return;
				}
				let _this = this;
				//按钮是禁用状态
				this.disabled = true;
				let num = 60;
				//this.phoneCode=num+"秒";
				var si = setInterval(() => {
					let n = num--;
					if (n < 1) {
						_this.disabled = false;
						clearInterval(si);
						document.getElementById("c").innerHTML = "重新发送验证码";
						document.getElementById("c").style.width="200px"
						this.yzCode = '';
						
					} else {
						this.phoneCode = n + "秒";
					}
				}, 1000)
				this.$axios.get("/getPwdCode", {
					params: {
						phone: this.modifyPwdForm.phone
					}
				}).then(res => {
					console.log(res.data.data.resCode);
					this.yzCode = res.data.data.resCode;
				}).catch(err => {
					this.$message.error("请联系管理员");
				});
			}, */
			//获取验证码
			getCode() {
				if (this.modifyPwdForm.phone == '') {
					this.$message({
						message: "请输入手机号",
						type: "warning",
						duration: 900
					});
				} else {
					let _this = this;
					let countdown = 61;
					let clear;
					//按钮是禁用状态
					this.disabled = true;
					this.$axios.get("getLoginCode", {
						params: {
							phone: this.modifyPwdForm.phone
						}
					}).then(res => {
						console.log(res.data.data.resCode);
						this.yzCode = res.data.data.resCode;
					}).catch(err => {
						console.log(err);
						this.$message({
							message: "请联系管理员",
							type: "error",
							duration: 900
						})
					});
					clear = setInterval(function() {
						countdown--;
						if (countdown == 0) {
							countdown = 61;
							_this.disabled = false;
							clearInterval(clear);
							document.getElementById("c").innerHTML = "重新发送验证码";
						} else {
							document.getElementById("c").innerHTML = countdown + "秒";
						}
			
					}, 1000)
				}
			
			},
			//手机号码验证通过
			submitForm1(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) { //验证通过
						this.checkPhoneHide = true;
						this.inputNewPwdHide = false;
						this.active = 1;
						//需要使用axios提交数据到后台
						//alert('submit!');
					} else { //验证未通过
						console.log('error submit!!');
						return false;
					}
				});
			},
			//输入新密码
			submitForm(formName) {
				
				var _this = this;
				this.$refs[formName].validate((valid) => {
					if (valid) { //验证通过
						//获取登录用户的id
						//var id = JSON.parse(sessionStorage.getItem("loginInfo")).id;
						//需要使用axios修改新密码成功
						let aa = {
							phone: this.modifyPwdForm.phone,
							password: this.modifyPwdForm1.userPwd
						}
						console.log(aa);
						this.$axios.post("/updPwdDoctor", JSON.stringify(aa), {
							"headers": {
								'Content-Type': 'application/json'
							}
						}).then(res => {
							console.log(res.data);
							this.checkPhoneHide = true;
							this.inputNewPwdHide = true;
							this.modifySuccessHide = false; //显示成功后的内容
							this.active = 2;
							let a = 4;
							 var inter = setInterval(function() {
								var c = --a;
								if (c != 0) {
									_this.subTitle = c + "秒后自动跳转至登录页"
								} else {
									clearInterval(inter); //清除定时器
									_this.$router.push('/'); //跳转到登录页
								}
							}, 1000); 
						}).catch(err => {
							this.$alert("请联系管理员");
						});
						
					} else { //验证未通过
						return false;
					}
				});
			},
		}
	}
</script>

<style scoped="scoped">
	.modifypwd,
	.el-container {
		width: 100%;
		height: 100%;
		/* background-image: url(../assets/bg1.jpeg); */
		background-repeat: no-repeat;
		background-position: right;
		background-size: 100% 100%;
		/* background-color: #f7fcff; */
		background-color: whitesmoke;
	}

	.el-main {
		height: 95%;
		width: 100%;
	}

	.f {
		position: relative;
		background-color: #444;
		padding: 10px 0 10px 0;
		color: #fff;
		bottom: 0rem;
	}

	.modifypwdcontent {
		width: 450px;
		height: 450px;
		border-radius: 0.3125rem;
		margin: 0px auto;
		margin-top: 4%;
		text-align: center;
		padding-left: 1.875rem;
		padding-right: 1.875rem;
		padding-top: 1.875rem;
		background-color: white;
	}
	h1{
		margin-top: 0px;
	}

	.el-steps {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	/* 下一步按钮 */
	.el-button--primary {
		background-color: rgb(24, 139, 157);
		border-color: rgb(24, 139, 157);
		font-size: 1rem;
	}

	#c {
		width: 6.25rem;
		height: 100%;
		border: none;
		background: rgb(24, 139, 157);
		position: relative;
		right: -0.3125rem;
		color: white;
	}
</style>
